<template>
  <div class="my-user">
    <router-view v-if="isRefresh" />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRefresh: true
    }
  },
  methods: {
    reload() {
      this.isRefresh = false
      this.$nextTick(() => {
        this.isRefresh = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.my-user {
  height: 100%;
}
</style>